import React from 'react';
import Layout from "../components/layout";
import styled from "styled-components";
import Helmet from 'react-helmet'

const FastApplication = () => {

    const FormContainer = styled.div`
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 150vh;
    overflow: hidden;
    
    `

    return (
        <Layout>
              <Helmet>
                    <title>Square One | Application</title>
                <meta name="title" content="Square One Fast Application" />
                <meta name="description" content="You're just a step away from getting preapproved!"/>
                <link rel="canonical" href="http://squareone-living.com/fastapp" />
                

          </Helmet>
            <FormContainer>
                    <iframe name="fast application form" title="fast application form" src="https://tfaforms.com/4851267" height="100%" width="100%"
                frameborder="0" ></iframe>
                <script src="//tfaforms.com/js/iframe_resize_helper.js"></script>
            </FormContainer>
        
        </Layout>

    )
   

}

export default FastApplication;